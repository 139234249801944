<template>
  <v-app  id="section0">
    <div>
      <navbar></navbar>
    </div>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './AppNavbar.vue';


export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
