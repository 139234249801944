import { createApp } from 'vue'
import App from './components/App.vue'
//import Vue from 'vue'

// Vuetify
import 'vuetify/styles'
import "vuetify/dist/vuetify.min.css";
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';

//Enrutamiento
import router from './routes/routes'

const vuetify = createVuetify({
    components,
    directives,
})

const app = createApp(App).use(vuetify)

app.use(router)
app.mount('#app');