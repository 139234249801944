<template>
    <div>
      <v-app-bar color="#140D2A" dark>
          <v-toolbar-title>
              <!-- <span class="web-title">AQUELARRE</span> -->
               <div>
                  <img :src="logoImg" alt="Logo aquelarre" style="width: 120px">
               </div>    
          </v-toolbar-title>
          <v-spacer class="d-none d-md-flex"></v-spacer>

          <v-app-bar-nav-icon @click="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
          <div class="toolbar-buttons d-none d-md-flex">
              <v-btn
                  text
                  v-for="item in items"
                  :key="item.title"
                  :href="item.path"
                  class="nav-link"
              >
                  <v-icon right> {{ item.icon }}</v-icon>
                  {{ item.title }}
              </v-btn>
          </div>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app temporary width="200" location="right">
        <v-list>
          <v-list-item v-for="item in items" :key="item.title" :href="item.path">
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
//import { computed } from 'vue'
//import { useRoute } from 'vue-router';

export default {
    name: 'AppNavbar',
    data(){
        return {
            drawer: false,
            items: [
                { title: 'INICIO', icon: 'mdi-home', path: '#section0'},
                { title: 'LA SAGA', icon: 'mdi-book', path: '#section1'},
                { title: 'GUILLE Y VERÓNICA', icon: 'mdi-account',  path: '#section3'},
            ],
            logoImg: require('@/assets/images/Logo-Aquelarre-Blanco.png'),
        }
    }
}
</script>

<style scoped>
@font-face {
  font-family: 'AppleGaramond';
  src: url('~@/assets/fonts/AppleGaramond/AppleGaramond.ttf') format('truetype'),
       url('~@/assets/fonts/AppleGaramond/AppleGaramond-Bold.ttf') format('truetype'),
       url('~@/assets/fonts/AppleGaramond/AppleGaramond-BoldItalic.ttf') format('truetype'),
       url('~@/assets/fonts/AppleGaramond/AppleGaramond-Italic.ttf') format('truetype'),
       url('~@/assets/fonts/AppleGaramond/AppleGaramond-Light.ttf') format('truetype'),
       url('~@/assets/fonts/AppleGaramond/AppleGaramond-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OptimusPrinceps';
  src: url('@/assets/fonts/OptimusPrinceps/OptimusPrinceps.ttf') format('truetype'),
       url('@/assets/fonts/OptimusPrinceps/OptimusPrincepsSemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.v-app-bar{
  background-color: #140D2A;
}

.toolbar-buttons {
  display: flex;
  align-items: center;
}

.web-title{
  font-family: 'OptimusPrinceps', serif;
  font-size: 30px;
  color: #F9FAF5;
}

.nav-link {
  font-family: 'OptimusPrinceps', serif;
  font-size: 15px;
  color: #F9FAF5;
  text-decoration: none;
  flex-grow: 1;
  text-align: center;
}

.nav-link v-icon {
  margin-right: 8px;
}

</style>