
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: '/', redirect: '/index' },
    { name: 'Index', path: '/index', component:()=> import('@/views/MainIndex.vue'), meta: { hideNavBar: false } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router